import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

import product from './modules/product'
import modal from './modules/modal'
import dealers from './modules/dealers'
import dealersOrders from './modules/dealersOrders'
import auth from './modules/auth'
import chats from './modules/chats'
import coordinators from './modules/coordinators'
import filtersOptions from './modules/filtersOptions'

const authState = createPersistedState({
  key: 'authentication-data',
  paths: [
    'auth.accessToken',
    'auth.refreshToken',
    'auth.manager.landingsAccess',
    'auth.manager.sellersAccess',
    'auth.manager.addwineAccess',
    'auth.manager.coordinatorAccess'
  ]
})

const rolesState = createPersistedState({
  key: 'access-data',
  paths: ['auth.role', 'auth.additionalRoles']
})

Vue.use(Vuex, axios)

export default new Vuex.Store({
  modules: {
    product,
    modal,
    dealers,
    dealersOrders,
    auth,
    chats,
    coordinators,
    filtersOptions
  },
  plugins: [authState, rolesState]
})
