import isAuth from '../middleware/isAuth'
import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'

const metaData = {
  middleware: [isAuth],
  layout: 'auth-layout',
}

export default {
  path: ADDEVENT_ADMIN_ROUTES.INDEX,
  component: () => import('@/views/addevent/Index.vue'),
  meta: metaData,
  children: [
    {
      path: '',
      name: 'AddeventMenu',
      component: () => import('@/views/addevent/Home.vue'),
      meta: metaData,
    },
    {
      path: ADDEVENT_ADMIN_ROUTES.CATALOG.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddeventCatalog',
          component: () => import('@/views/addevent/catalog/Home.vue'),
          meta: metaData,
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventCategories',
              component: () =>
                import('@/views/addevent/catalog/categories/List.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddeventCategoriesCreate',
              component: () =>
                import('@/views/addevent/catalog/categories/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddeventCategoriesEdit',
              component: () =>
                import('@/views/addevent/catalog/categories/Edit.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.CATALOG_PATH,
              name: 'AddeventCategoriesCatalog',
              component: () =>
                import('@/views/addevent/catalog/categories/Catalog.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CATALOG.ASSORTMENTS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventAssortments',
              component: () =>
                import('@/views/addevent/catalog/assortments/List.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddeventAssortmentsCreate',
              component: () =>
                import('@/views/addevent/catalog/assortments/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddeventAssortmentsEdit',
              component: () =>
                import('@/views/addevent/catalog/assortments/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CATALOG.SOLUTIONS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventSolutions',
              component: () =>
                import('@/views/addevent/catalog/solutions/List.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddeventSolutionsCreate',
              component: () =>
                import('@/views/addevent/catalog/solutions/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddeventSolutionsEdit',
              component: () =>
                import('@/views/addevent/catalog/solutions/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventProducts',
              component: () =>
                import('@/views/addevent/catalog/products/List.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddeventProductsCreate',
              component: () =>
                import('@/views/addevent/catalog/products/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddeventProductsEdit',
              component: () =>
                import('@/views/addevent/catalog/products/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CATALOG.BRANDS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventBrands',
              component: () =>
                import('@/views/addevent/catalog/brands/List.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CATALOG.TAGS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventTags',
              component: () => import('@/views/addevent/catalog/tags/List.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
    {
      path: ADDEVENT_ADMIN_ROUTES.ORDERS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddeventOrdersList',
          component: () => import('@/views/addevent/orders/List.vue'),
          meta: metaData,
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CREATE_PATH,
          name: 'AddeventOrdersCreate',
          component: () => import('@/views/addevent/orders/Create.vue'),
          meta: metaData,
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
          name: 'AddeventOrdersUpdate',
          component: () => import('@/views/addevent/orders/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDEVENT_ADMIN_ROUTES.ORDERS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: ADDEVENT_ADMIN_ROUTES.ORDERS_CALENDAR_INDEX,
          name: 'AddeventOrderCalendar',
          component: () => import('@/views/addevent/OrdersCalendar.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDEVENT_ADMIN_ROUTES.ORDERS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: ADDEVENT_ADMIN_ROUTES.SETTINGS_INDEX,
          name: 'AddeventSettings',
          component: () => import('@/views/addevent/Settings.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDEVENT_ADMIN_ROUTES.BLOG.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddeventBlog',
          component: () => import('@/views/addevent/blog/Home.vue'),
          meta: metaData,
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventArticleCategories',
              component: () =>
                import('@/views/addevent/blog/categories/List.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddeventArticleCategoriesCreate',
              component: () =>
                import('@/views/addevent/blog/categories/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddeventArticleCategoriesUpdate',
              component: () =>
                import('@/views/addevent/blog/categories/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.BLOG.ARTICLES.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventArticles',
              component: () =>
                import('@/views/addevent/blog/articles/List.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddeventArticlesCreate',
              component: () =>
                import('@/views/addevent/blog/articles/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddeventArticlesUpdate',
              component: () =>
                import('@/views/addevent/blog/articles/Edit.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
    {
      path: ADDEVENT_ADMIN_ROUTES.CONTENT.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddeventContentMenu',
          component: () => import('@/views/addevent/content/Home.vue'),
          meta: metaData,
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CONTENT.SEO_TEMPLATES.PATH,
          name: 'AddeventSeoTemplatesPage',
          component: () => import('@/views/addevent/content/SeoTemplates.vue'),
          meta: metaData,
        },
        {
          path: ADDEVENT_ADMIN_ROUTES.CONTENT.PAGES.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddeventContentPages',
              component: () =>
                import('@/views/addevent/content/pages/List.vue'),
              meta: metaData,
            },
            {
              path: ADDEVENT_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddeventContentEditPage',
              component: () =>
                import('@/views/addevent/content/pages/Edit.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
  ],
}
