import isAuth from '../middleware/isAuth'
import hasAddwineAccess from '@/router/middleware/hasAddwineAccess'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

const metaData = {
  middleware: [isAuth, hasAddwineAccess],
  layout: 'auth-layout',
}

export default {
  path: ADDWINE_ADMIN_ROUTES.INDEX,
  component: () => import('@/views/addwine/Index.vue'),
  meta: metaData,
  children: [
    {
      path: '',
      name: 'AddwineMenu',
      component: () => import('@/views/addwine/Home.vue'),
      meta: metaData,
    },
    {
      path: ADDWINE_ADMIN_ROUTES.SETTINGS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineSettingsMenu',
          component: () => import('@/views/addwine/settings/Home.vue'),
          meta: metaData,
        },
        {
          path: 'payment',
          name: 'AddwinePaymentSettings',
          component: () =>
            import('@/views/addwine/settings/PaymentSettings.vue'),
          meta: metaData,
        },
        {
          path: 'delivery-days',
          name: 'AddwineDeliveryDaysSettings',
          component: () => import('@/views/addwine/settings/DeliveryDays.vue'),
          meta: metaData,
        },
        {
          path: ADDWINE_ADMIN_ROUTES.SETTINGS.NOTIFICATIONS.PATH,
          name: 'AddwineNotificationsSettings',
          component: () =>
            import('@/views/addwine/settings/NotificationSettings.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.CATALOG.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineGoodsMenu',
          component: () => import('@/views/addwine/catalog/Home.vue'),
          meta: metaData,
        },
        {
          path: ADDWINE_ADMIN_ROUTES.CATALOG.BRANDS.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineBrandsList',
              component: () =>
                import('@/views/addwine/catalog/brands/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineBrandCreate',
              component: () =>
                import('@/views/addwine/catalog/brands/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineBrandEdit',
              component: () =>
                import('@/views/addwine/catalog/brands/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'categories',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineCategoriesTree',
              component: () =>
                import('@/views/addwine/catalog/categories/Tree.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineCategoriesCreate',
              component: () =>
                import('@/views/addwine/catalog/categories/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineCategoriesEdit',
              component: () =>
                import('@/views/addwine/catalog/categories/Edit.vue'),
              meta: metaData,
            },
            {
              path: ':id/catalog',
              name: 'AddwineCategoriesCatalog',
              component: () =>
                import('@/views/addwine/catalog/categories/Catalog.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'assortments',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineAssortmentsList',
              component: () =>
                import('@/views/addwine/catalog/assortments/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineAssortmentsCreate',
              component: () =>
                import('@/views/addwine/catalog/assortments/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineAssortmentsEdit',
              component: () =>
                import('@/views/addwine/catalog/assortments/Edit.vue'),
              meta: metaData,
            },
            {
              path: ':id/catalog',
              name: 'AddwineAssortmentsCatalog',
              component: () =>
                import('@/views/addwine/catalog/assortments/Catalog.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'groups',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineGroupsList',
              component: () =>
                import('@/views/addwine/catalog/groups/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineGroupsCreate',
              component: () =>
                import('@/views/addwine/catalog/groups/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineGroupsEdit',
              component: () =>
                import('@/views/addwine/catalog/groups/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'attributes',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineAttributesList',
              component: () =>
                import('@/views/addwine/catalog/attributes/List.vue'),
              meta: metaData,
            },
            {
              path: 'ranking',
              name: 'AddwineAttributesRanking',
              component: () =>
                import('@/views/addwine/catalog/attributes/Ranking.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineAttributeCreate',
              component: () =>
                import('@/views/addwine/catalog/attributes/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineAttributeEdit',
              component: () =>
                import('@/views/addwine/catalog/attributes/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'products',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineProductsList',
              component: () =>
                import('@/views/addwine/catalog/products/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineProductCreate',
              component: () =>
                import('@/views/addwine/catalog/products/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineProductEdit',
              component: () =>
                import('@/views/addwine/catalog/products/Edit.vue'),
              meta: metaData,
            },
            {
              path: ':id/subscriptions',
              name: 'AddwineProductSubscriptions',
              component: () =>
                import('@/views/addwine/catalog/products/Subscriptions.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.PAYMENTS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwinePaymentsMenu',
          component: () => import('@/views/addwine/payments/Home.vue'),
          meta: metaData,
        },
        {
          path: 'payment-types',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwinePaymentTypesList',
              component: () =>
                import('@/views/addwine/payments/paymentTypes/List.vue'),
              meta: metaData,
            },

            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwinePaymentTypesEdit',
              component: () =>
                import('@/views/addwine/payments/paymentTypes/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'locations-delivery',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineLocationDeliveryList',
              component: () =>
                import('@/views/addwine/payments/location-delivery/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineLocationDeliveryCreate',
              component: () =>
                import('@/views/addwine/payments/location-delivery/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineLocationDeliveryEdit',
              component: () =>
                import('@/views/addwine/payments/location-delivery/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'order-statuses',
          name: 'AddwineOrderStatuses',
          component: () => import('@/views/addwine/payments/OrderStatuses.vue'),
          meta: metaData,
        },
        {
          path: 'payment-statuses',
          name: 'AddwinePaymentStatuses',
          component: () =>
            import('@/views/addwine/payments/PaymentStatuses.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.ORDERS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineOrdersList',
          component: () => import('@/views/addwine/orders/List.vue'),
          meta: metaData,
        },
        {
          path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
          name: 'AddwineOrderCreate',
          component: () => import('@/views/addwine/orders/Create.vue'),
          meta: metaData,
        },
        {
          path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
          name: 'AddwineOrderView',
          component: () => import('@/views/addwine/orders/View.vue'),
          meta: metaData,
        },
        {
          path: ':id/edit',
          name: 'AddwineOrderEdit',
          component: () => import('@/views/addwine/orders/Edit.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.BLOG.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineBlogsMenu',
          component: () => import('@/views/addwine/blog/Home.vue'),
          meta: metaData,
        },
        {
          path: 'articles',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineBlogArticlesList',
              component: () => import('@/views/addwine/blog/articles/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineBlogArticlesCreate',
              component: () =>
                import('@/views/addwine/blog/articles/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineBlogArticlesEdit',
              component: () => import('@/views/addwine/blog/articles/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'categories',
          name: 'AddwineBlogArticleCategories',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineBlogArticlesList',
              component: () =>
                import('@/views/addwine/blog/categories/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineBlogArticleCategoriesCreate',
              component: () =>
                import('@/views/addwine/blog/categories/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineBlogArticleCategoriesEdit',
              component: () =>
                import('@/views/addwine/blog/categories/Edit.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.PROMOTIONS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwinePromotionsMenu',
          component: () => import('@/views/addwine/promotions/Home.vue'),
          meta: metaData,
        },
        {
          path: 'promo-articles',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwinePromoArticlesList',
              component: () =>
                import('@/views/addwine/promotions/promoArticles/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwinePromoArticlesCreate',
              component: () =>
                import('@/views/addwine/promotions/promoArticles/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwinePromoArticlesEdit',
              component: () =>
                import('@/views/addwine/promotions/promoArticles/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'promos',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwinePromosMenu',
              component: () =>
                import('@/views/addwine/promotions/promos/Home.vue'),
              meta: metaData,
            },
            {
              path: 'ad-promos',
              component: () => import('@/components/atoms/Index.vue'),
              meta: metaData,
              children: [
                {
                  path: '',
                  name: 'AddwineAdPromosList',
                  component: () =>
                    import(
                      '@/views/addwine/promotions/promos/adPromos/List.vue'
                    ),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
                  name: 'AddwineAdPromosCreate',
                  component: () =>
                    import(
                      '@/views/addwine/promotions/promos/adPromos/Create.vue'
                    ),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
                  name: 'AddwineAdPromosEdit',
                  component: () =>
                    import(
                      '@/views/addwine/promotions/promos/adPromos/Edit.vue'
                    ),
                  meta: metaData,
                },
              ],
            },
            {
              path: 'gift-promos',
              component: () => import('@/components/atoms/Index.vue'),
              meta: metaData,
              children: [
                {
                  path: '',
                  name: 'AddwineGiftPromosList',
                  component: () =>
                    import(
                      '@/views/addwine/promotions/promos/giftPromos/List.vue'
                    ),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
                  name: 'AddwineGiftPromosCreate',
                  component: () =>
                    import(
                      '@/views/addwine/promotions/promos/giftPromos/Create.vue'
                    ),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
                  name: 'AddwineGiftPromosView',
                  component: () =>
                    import(
                      '@/views/addwine/promotions/promos/giftPromos/View.vue'
                    ),
                  meta: metaData,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.CONTENT.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineContentMenu',
          component: () => import('@/views/addwine/content/Home.vue'),
          meta: metaData,
        },
        {
          path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineContentPages',
              component: () => import('@/views/addwine/content/pages/Home.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CONTACTS.PATH,
              name: 'AddwineContactsPage',
              component: () =>
                import('@/views/addwine/content/pages/Contacts.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ABOUT.PATH,
              name: 'AddwineAboutPage',
              component: () =>
                import('@/views/addwine/content/pages/About.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.BONUS_INFO.PATH,
              name: 'AddwineBonusInfoPage',
              component: () =>
                import('@/views/addwine/content/pages/BonusInfo.vue'),
              meta: metaData,
            },
            {
              path: 'main',
              name: 'AddwineMainPage',
              component: () => import('@/views/addwine/content/pages/Main.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ARTICLES.PATH,
              name: 'AddwineBlogPage',
              component: () =>
                import('@/views/addwine/content/pages/BlogArticles.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.WARRANTY.PATH,
              name: 'AddwineWarrantyPage',
              component: () =>
                import('@/views/addwine/content/pages/Warranty.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.BECOME_A_SUPPLIER.PATH,
              name: 'AddwineBecomeASupplierPage',
              component: () =>
                import('@/views/addwine/content/pages/BecomeASupplier.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PURCHASE_RETURNS.PATH,
              name: 'AddwinePurchaseReturnsPage',
              component: () =>
                import('@/views/addwine/content/pages/PurchaseReturns.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PURCHASE_CANCELLATION
                .PATH,
              name: 'AddwinePurchaseCancellationPage',
              component: () =>
                import(
                  '@/views/addwine/content/pages/PurchaseCancellation.vue'
                ),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PRIVATE_POLICY.PATH,
              name: 'AddwinePolicyPage',
              component: () =>
                import('@/views/addwine/content/pages/PrivacyPolicy.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CATALOGUE.PATH,
              name: 'AddwineCataloguePage',
              component: () =>
                import('@/views/addwine/content/pages/Catalogue.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PROMOTIONS.PATH,
              name: 'AddwinePromotionsPage',
              component: () =>
                import('@/views/addwine/content/pages/Promotions.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.DELIVERY.PATH,
              name: 'AddwineDeliveryPage',
              component: () =>
                import('@/views/addwine/content/pages/Delivery.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.COMMON.PATH,
              name: 'AddwineCommonPage',
              component: () =>
                import('@/views/addwine/content/pages/Common.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.ORDER_INFO.PATH,
              name: 'AddwineOrderInfoPage',
              component: () =>
                import('@/views/addwine/content/pages/OrderInfo.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CLIENTS.PATH,
              name: 'AddwineClientsPage',
              component: () =>
                import('@/views/addwine/content/pages/Clients.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PARTNERS.PATH,
              name: 'AddwinePartnersPage',
              component: () =>
                import('@/views/addwine/content/pages/Partners.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.VACANCIES.PATH,
              name: 'AddwineVacanciesPage',
              component: () =>
                import('@/views/addwine/content/pages/Vacancies.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.DOCUMENTS.PATH,
              name: 'AddwineDocumentsPage',
              component: () =>
                import('@/views/addwine/content/pages/Documents.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PROCESSING_POLICY.PATH,
              name: 'AddwineProcessingPolicyPage',
              component: () =>
                import('@/views/addwine/content/pages/ProcessingPolicy.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.PUBLIC_OFFER.PATH,
              name: 'AddwinePublicOfferPage',
              component: () =>
                import('@/views/addwine/content/pages/PublicOffer.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.COOKIES_POLICY.PATH,
              name: 'AddwineCookiesPolicyPage',
              component: () =>
                import('@/views/addwine/content/pages/CookiesPolicy.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.CERTIFICATES_POLICY.PATH,
              name: 'AddwineCertificatesPolicyPage',
              component: () =>
                import('@/views/addwine/content/pages/CertificatesPolicy.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.FAQ.PATH,
              name: 'AddwineFaqPage',
              component: () => import('@/views/addwine/content/pages/Faq.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.TEAM.PATH,
              name: 'AddwineTeamPage',
              component: () => import('@/views/addwine/content/pages/Team.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.PAGES.REVIEWS.PATH,
              name: 'AddwineReviewsPage',
              component: () =>
                import('@/views/addwine/content/pages/Reviews.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'seo-templates',
          name: 'AddwineContentSeoTemplates',
          component: () => import('@/views/addwine/content/templates/List.vue'),
          meta: metaData,
        },
        {
          path: 'seo-texts',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineSeoTextsList',
              component: () =>
                import('@/views/addwine/content/seo-texts/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineSeoTextsCreate',
              component: () =>
                import('@/views/addwine/content/seo-texts/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineSeoTextsEdit',
              component: () =>
                import('@/views/addwine/content/seo-texts/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'seo-faqs',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineSEOFAQsList',
              component: () =>
                import('@/views/addwine/content/seo-faqs/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineSEOFAQsCreate',
              component: () =>
                import('@/views/addwine/content/seo-faqs/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineSEOFAQsEdit',
              component: () =>
                import('@/views/addwine/content/seo-faqs/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'vacancies',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineVacanciesList',
              component: () =>
                import('@/views/addwine/content/vacancies/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineVacanciesCreate',
              component: () =>
                import('@/views/addwine/content/vacancies/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineVacanciesEdit',
              component: () =>
                import('@/views/addwine/content/vacancies/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'documents',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineDocumentsList',
              component: () =>
                import('@/views/addwine/content/documents/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineDocumentsCreate',
              component: () =>
                import('@/views/addwine/content/documents/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineDocumentsEdit',
              component: () =>
                import('@/views/addwine/content/documents/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'reviews',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineReviewsList',
              component: () =>
                import('@/views/addwine/content/reviews/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineReviewsCreate',
              component: () =>
                import('@/views/addwine/content/reviews/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineReviewsEdit',
              component: () =>
                import('@/views/addwine/content/reviews/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'clients',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineClientsMenu',
              component: () =>
                import('@/views/addwine/content/clients/Home.vue'),
              meta: metaData,
            },
            {
              path: 'categories',
              name: 'AddwineClientCategoriesList',
              component: () =>
                import('@/views/addwine/content/clients/categories/List.vue'),
              meta: metaData,
            },
            {
              path: 'clients',
              component: () => import('@/components/atoms/Index.vue'),
              meta: metaData,
              children: [
                {
                  path: '',
                  name: 'AddwineClientsList',
                  component: () =>
                    import('@/views/addwine/content/clients/clients/List.vue'),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
                  name: 'AddwineClientsCreate',
                  component: () =>
                    import(
                      '@/views/addwine/content/clients/clients/Create.vue'
                    ),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
                  name: 'AddwineClientsEdit',
                  component: () =>
                    import('@/views/addwine/content/clients/clients/Edit.vue'),
                  meta: metaData,
                },
              ],
            },
          ],
        },
        {
          path: 'partners',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwinePartnersMenu',
              component: () =>
                import('@/views/addwine/content/partners/Home.vue'),
              meta: metaData,
            },
            {
              path: 'categories',
              name: 'AddwinePartnerCategoriesList',
              component: () =>
                import('@/views/addwine/content/partners/categories/List.vue'),
              meta: metaData,
            },
            {
              path: 'partners',
              component: () => import('@/components/atoms/Index.vue'),
              meta: metaData,
              children: [
                {
                  path: '',
                  name: 'AddwinePartnersList',
                  component: () =>
                    import(
                      '@/views/addwine/content/partners/partners/List.vue'
                    ),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
                  name: 'AddwinePartnersCreate',
                  component: () =>
                    import(
                      '@/views/addwine/content/partners/partners/Create.vue'
                    ),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
                  name: 'AddwinePartnersEdit',
                  component: () =>
                    import(
                      '@/views/addwine/content/partners/partners/Edit.vue'
                    ),
                  meta: metaData,
                },
              ],
            },
          ],
        },
        {
          path: 'team-members',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineTeamMembersList',
              component: () =>
                import('@/views/addwine/content/team-members/List.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
              name: 'AddwineTeamMembersCreate',
              component: () =>
                import('@/views/addwine/content/team-members/Create.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
              name: 'AddwineTeamMembersEdit',
              component: () =>
                import('@/views/addwine/content/team-members/Edit.vue'),
              meta: metaData,
            },
          ],
        },
        {
          path: 'faqs',
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineFAQsMenu',
              component: () => import('@/views/addwine/content/faqs/Home.vue'),
              meta: metaData,
            },
            {
              path: 'categories',
              name: 'AddwineFAQsCategoriesList',
              component: () =>
                import('@/views/addwine/content/faqs/categories/List.vue'),
              meta: metaData,
            },
            {
              path: 'faqs',
              component: () => import('@/components/atoms/Index.vue'),
              meta: metaData,
              children: [
                {
                  path: '',
                  name: 'AddwineFAQsList',
                  component: () =>
                    import('@/views/addwine/content/faqs/faqs/List.vue'),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
                  name: 'AddwineFAQsCreate',
                  component: () =>
                    import('@/views/addwine/content/faqs/faqs/Create.vue'),
                  meta: metaData,
                },
                {
                  path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
                  name: 'AddwineFAQsEdit',
                  component: () =>
                    import('@/views/addwine/content/faqs/faqs/Edit.vue'),
                  meta: metaData,
                },
              ],
            },
          ],
        },
        {
          path: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.PATH,
          component: () => import('@/components/atoms/Index.vue'),
          meta: metaData,
          children: [
            {
              path: '',
              name: 'AddwineSeo',
              component: () => import('@/views/addwine/content/seo/Home.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.ROBOTS_TXT.PATH,
              name: 'AddwineSeoRobotsTxt',
              component: () =>
                import('@/views/addwine/content/seo/robots-txt/Index.vue'),
              meta: metaData,
            },
            {
              path: ADDWINE_ADMIN_ROUTES.CONTENT.SEO.SITEMAP.PATH,
              name: 'AddwineSeoSitemap',
              component: () =>
                import('@/views/addwine/content/seo/sitemap/Index.vue'),
              meta: metaData,
            },
          ],
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.USERS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineUsersList',
          component: () => import('@/views/addwine/users/List.vue'),
          meta: metaData,
        },
        {
          path: ADDWINE_ADMIN_ROUTES.CREATE_PATH,
          name: 'AddwineUsersCreate',
          component: () => import('@/views/addwine/users/Create.vue'),
          meta: metaData,
        },
        {
          path: ADDWINE_ADMIN_ROUTES.UPDATE_PATH,
          name: 'AddwineUsersEdit',
          component: () => import('@/views/addwine/users/Edit.vue'),
          meta: metaData,
        },
        {
          path: ':id/orders',
          name: 'AddwineUsersOrders',
          component: () => import('@/views/addwine/users/Orders.vue'),
          meta: metaData,
        },
        {
          path: ':id/subscriptions',
          name: 'AddwineUsersSubscriptions',
          component: () => import('@/views/addwine/users/Subscriptions.vue'),
          meta: metaData,
        },
        {
          path: ':id/bonus-transactions',
          name: 'AddwineUsersBonusTransactions',
          component: () =>
            import('@/views/addwine/users/BonusTransactions.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.SUBSCRIPTIONS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineSubscriptionsList',
          component: () => import('@/views/addwine/subscriptions/List.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.ANALYTICS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineAnalytics',
          component: () => import('@/views/addwine/analytics/View.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.FEEDBACKS.PATH,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: '',
          name: 'AddwineFeedbacks',
          component: () => import('@/views/addwine/feedbacks/List.vue'),
          meta: metaData,
        },
      ],
    },
    {
      path: ADDWINE_ADMIN_ROUTES.INDEX,
      component: () => import('@/components/atoms/Index.vue'),
      meta: metaData,
      children: [
        {
          path: ADDWINE_ADMIN_ROUTES.SETTINGS_PDF_INDEX,
          name: 'AddwineSettingsPdf',
          component: () => import('@/views/addwine/SettingsPdf.vue'),
          meta: metaData,
        },
      ],
    },
  ],
}
